@font-face {
  font-family: Roboto-Regular;
  src: url("./Assets/Fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Roboto-Light;
  src: url("./Assets/Fonts/Roboto-Light.ttf");
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.roboto-light {
  font-family: Roboto-Light;
}

.max-w960 {
  max-width: 960px;
  margin: auto;
}

.text-danger2 {
  color: #d96459;
}

.login_icon {
  height: 12px;
  margin-right: 3px;
}

.applogo {
  height: 34px;
}

.authHeader_wrapper {
  /* max-width: 960px;
  margin: auto; */
}

strong {
  font-weight: bolder;
}

.authHeader_wrapper .navbar {
  padding: 0 !important;
  padding-top: 5px !important;
}

.authHeader_wrapper .nav-link {
  text-transform: uppercase;
  color: #428bca !important;
  margin-left: 15px;
}

.authHeader_wrapper .nav-link:hover {
  color: #333 !important;
}

#footer {
  margin-top: 20px;
  padding-top: 40px;
  height: 170px;
  font-size: 14px;
  border-top: 1px solid #d6d6d6;
  background-color: #f2f2f2;
  color: #333;
}

#footer a {
  color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

a {
  color: #337ab7 !important;
  text-decoration: none;
}

.text-info1 {
  color: #337ab7 !important;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

label.required:after {
  content: " *";
  color: red;
}

.dashbaord_header {
  display: flex;
  justify-content: space-between;
  background-color: #e9e9e9;
  border-bottom: 1px solid #d8d8d8;
  color: #0074b6 !important;
  padding: 10px 5px;
}

.links_container {
  display: flex;
  align-items: center;
}

.links_container>div {
  padding: 0px 15px;
}

.dashboardDropDown button {
  background: transparent !important;
  color: #0074b6 !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.links_container a {
  color: #0074b6 !important;
  font-size: 14px !important;
}

.dashboardDropDown button:hover {
  background: transparent !important;
  color: #0074b6 !important;
  border: none !important;
  box-shadow: none !important;
}

.dashboardDropDown .dropdown-item {
  display: block !important;
  padding: 3px 20px !important;
  clear: both !important;
  font-weight: 400 !important;
  line-height: 1.428571429 !important;
  color: #333333 !important;
  white-space: nowrap !important;
  text-decoration: none;
  font-size: 12px;
}

.dashboardDropDown .dropdown-item:active {
  color: white !important;
  background-color: #333333 !important;
}

.success_row {
  background-color: #dff0d8;
}

.danger_row {
  background-color: #f2dede;
}



.error_color {
  color: #dc3545;
}

.alert-info {
  color: #31708f;
  background-color: #f2f2f2;
  border-color: #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default>.panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.panel-body {
  font-size: 14px;
}

.label-default {
  background-color: #777777;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.table-condensed>thead>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>tfoot>tr>td {
  padding: 5px;
  font-size: 14px;
}

.panel-danger {
  border-color: #ebccd1 !important;
}

.panel-danger .panel-heading {
  border-color: #ebccd1 !important;
  background-color: #ebccd1 !important;
}

.table>thead>tr>td.success,
.table>thead>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th {
  background-color: #dff0d8;
}

.table>thead>tr>td.danger,
.table>thead>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th {
  background-color: #f2dede;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
}

.panel-default>.panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333;
}

.label-primary {
  background-color: #337ab7;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.formEl {
  text-align: center;
}

.swal2-styled.swal2-confirm {
  background-color: #337ab7 !important;
}

.formEl>div>div:first-child {
  width: 400px;
  text-align: right;
}

.formEl>div>div:last-child>* {
  width: 200px;
  text-align: left;
}

.formEl>div>div:last-child {
  width: 400px;
  text-align: left;
}

.panel-info {
  border-color: #bce8f1 !important;
}

.page-center-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* ************************** */
.user_dashboard_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #EDEDED;
  border-bottom: 1px solid gainsboro;
  padding: 0px 22px;
  flex-wrap: wrap;
}

.user_top_wrapper {
  /* background-color: #EDEDED; */
  background-color: white;
  border-bottom: 1px solid gainsboro;
}

.user_dashboard_wrapper>div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.font-14 {
  font-size: 14px;
}

.user_dashboard_wrapper a {
  padding: 14px 15px !important;
  text-decoration: none !important;
  display: inline-block;
  color: #737d83 !important;
  font-size: 14px;
  cursor: pointer !important;
}

.user_dashboard_wrapper a:hover {
  background-color: #d75d39;
  color: white !important;
}

.user_dashboard_wrapper a.active {
  background-color: #d75d39;
  color: white !important;
}

.user_dashboard_wrapper a i {
  margin-right: 3px;
}

.user_dropdown button {
  color: #737d83 !important;
  font-size: 14px;
}

.user_dropdown button i {
  margin-right: 3px;
}

.user_dropdown button:hover {
  color: #737d83 !important;
}

.setting-font {
  font-size: 12px;
}

.help_navigation_bar li {
  width: 100%;
}

.help_navigation_bar li a {
  display: block !important;
  color: white;
  width: 100%;
  text-decoration: none !important;
}


.help_section h4,
.help_section h3 {
  color: #D96459;
  font-size: 18px;
  font-weight: 400;
}

.label-info {
  background-color: #5bc0de;
}


.default_user_change_penal {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.default_user_change_penal a {
  text-decoration: none;
  border: 1px solid #ccc;
  padding: 7px;
  text-align: center;
  color: #333 !important;
  font-size: 14px;
  cursor: pointer;
  width: 100% !important;
}

.default_user_change_penal a:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.default_user_change_penal a.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.label-danger {
  background-color: #d9534f;
}

.setting-dropdown {
  color: #333 !important;
  background-color: #fff !important;
  border-color: 1px solid #ccc !important;
  border-radius: 0px;
  border-color: #ccc !important;
  outline: none !important;
  box-shadow: none !important;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.label-success {
  background-color: #5cb85c;
}

.panel-info>.panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  display: flex;
  justify-content: space-between;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-disabled {
  color: #000000;
  background-color: #cdcbcb;
  border-color: #bce8f1;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 1px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.accordion_1 button.accordion-header {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px !important;
  box-shadow: none !important;
}

.accordion_1 button.accordion-button:not(.collapsed) {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px 21px !important;
  box-shadow: none !important;
}

.sortTableIconsUp {
  margin-right: -9px;
  cursor: pointer;
}

.rdt_TableCol_Sortable {
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: bolder;
}

.pointer {
  cursor: pointer;
}

.accordion_1 button::after {
  /* display: none; */
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.border-danger {
  border: 1px solid red;
  box-shadow: none !important;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-disabled {
  background-color: #654353;
}

.alert-warning {
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
  border-color: #faebcc !important;
}

.itemsTableDropDown button {
  color: #fff !important;
  background-color: #ec971f !important;
  background-image: none !important;
  border-color: #d58512 !important;
}

.itemsTableDropDown.success button {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}

.itemsTableDropDown.yellow button {
  background-color: #ec971f !important;
  border-color: #d58512 !important;
  color: white !important;
}

.itemsTableDropDown.danger button {
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
}

.itemsTableDropDown.success:hover button {
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  border-color: #4cae4c !important;
}

.itemsTableDropDown.danger:hover button {
  color: #fff !important;
  background-image: none !important;
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
}

.camWeb {
  width: 320px;
  height: 240px;
  display: block;
  position: relative;
}

.camWeb video {
  width: 320px;
  height: 240px;
  display: block;
  overflow: hidden;
}

.camWeb .sc-dkzDqf {
  color: red;
}

.pointer {
  cursor: pointer;
}

.signatureContainer .sigCanvas {
  width: 100%;
  height: 200px;
}

.addItemsContainers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  flex-wrap: wrap;
}

.btn-bordered {
  background-color: transparent;
  font-size: 14px !important;
  border: 1px solid;
  padding: 5px 10px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.itemsBtnDropDown button {
  background-color: transparent !important;
  border: 1px solid !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
  color: #333 !important;
  background-color: #fff !important;
  border-color: #ccc !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.dropDownList a {
  display: block !important;
  padding: 3px 20px !important;
  clear: both !important;
  font-weight: 400 !important;
  line-height: 1.428571429 !important;
  color: #333333 !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  text-decoration: none;
  font-size: 12px;
}

.btn-info {
  color: #fff !important;
  background-color: #5bc0de !important;
  border-color: #46b8da !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.itemsSearchContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px !important;
  border-radius: 5px;
  /* overflow: hidden; */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.itemsSearchContainer>div:first-child {
  /* background-color: red; */
  border: 1px solid #ccc;
}

.user_top_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #d96459;
}

.user_top_wrapper {
  display: none;
}

.user_top_wrapper img {
  width: 150px;
}

.user_top_wrapper i {
  font-size: 20px;
}

.itemsSearchContainer input {
  font-size: 14px !important;
  border: none !important;
  outline: none !important;
  height: 32px;
  width: 330px;
  color: #555555;
  padding: 0px 5px;
}

.btn-default {
  color: #333 !important;
  background-color: #fff;
  border-color: #ccc;
  font-size: 12px;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning>.panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.print-table {
  width: 100%;
}

.print-table td {
  font-size: 12px;
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #dddddd;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.panel-info>.panel-heading {
  color: #31708f;
  background-color: #f2f2f2;
  border-color: #dddddd;
}

.plan_table {
  border: 1px solid #BBBBBB;
  font-size: 14px;
  margin-bottom: 20px;
}



.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3 !important;
}

.plan_table .right {
  border-left: 1px solid #BBBBBB;
}

.table tr td {
  padding: 5px !important;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.overflowWrap {
  overflow-wrap: anywhere;
}


.list-unstyle {
  list-style: none;
  padding-left: 0;
}

button:not(.react-datepicker__navigation) {
  z-index: 0 !important;
}

.publicPictures {
  max-width: 250px;
}

.react-datepicker__navigation {
  align-items: center;
}

.react-datepicker__navigation>span::before {
  top: 14px;
}

table {
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.unauthorized_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  text-align: center;
}

.unauthorized_container i {
  font-size: 50px;
  margin-bottom: 50px;
  color: #d96459;

}


.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -22px !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  left: -22px !important;
}

.advanceFilter.active {
  color: #fff !important;
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
}





.text-elips {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .custom-tool-tip div.popover-body {
  background-color: red !important;
}

.custom-tool-tip .popover-body {
  background-color: red !important;
} */

.no-wrap {
  white-space: initial !important;
}


.ToolTipContainer {
  position: relative;
  display: inline;
  cursor: pointer;
}

.toolTopWrapper {
  position: absolute;
  top: 30px;
  right: -150px;
  background-color: white;
  padding: 0.5rem 0rem;
  padding-top: 0;
  color: #212529;
  background-color: white;
  opacity: 1;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  display: none;
  font-size: 14px;
}

.ToolTipContainer .arrow {
  position: absolute;
  top: 22px;
  right: -8px;
  background-color: red;
  /* width: 20px;
  height: 20px; */
  display: block;
  background-color: white;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border: 15px solid rgba(0, 0, 0, .2);
  border-bottom-color: transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  z-index: 999;
  display: none;
}

.ToolTipContainer:hover .toolTopWrapper,
.ToolTipContainer:hover .arrow {
  display: block;
}

.fa-question-circle {
  color: #70c8e2
}


.alert-warning {
  color: #8A6D3B !important;
  background-color: #FCF8E3 !important;
  border-color: #FAEBCC !important;
}

.table-condensed .bg-warning {
  background-color: #ffc107a6 !important;
}

.table-condensed .bg-success {
  background-color: #28a7455e !important;
}

.table-condensed .bg-danger {
  background-color: #dc35456e !important;
}

.button-margin {
  margin-right: 30px;
}

.address-top-margin {
  margin-top: 20px;
}

.address-bottom-margin {
  margin-bottom: 20px;
}

.residential-check {
  display: 'inline-flex';
  align-items: 'center';
  margin-bottom: 10px;
}

.react-datepicker-popper {
  z-index: 10;
}